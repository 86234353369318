import styled from '@emotion/styled'
import { Select } from '@common/components/Select'
import { useCallback, useState } from 'react'
import { Editor } from '@tiptap/react'
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  BoldIcon, FileTextIcon, ItalicIcon, LinkIcon, ListIcon, OrderedListIcon,
  UnderlineIcon,
} from '../../block-editor/icons'
import { SelectDocumentModal } from '../../block-editor/SelectDocumentModal'

const ActionContainer = styled.div`
  select {
    border: none;
    border-right: 1px solid ${({ theme }) => theme.onBackgroundLighter};

    min-width: 8rem;
    padding-left: 1rem;
  }

  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;

  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.onBackgroundLighter};

  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);

  svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    color: ${({ theme }) => theme.onBackgroundLight};
  }

  .active {
    color: ${({ theme }) => theme.primaryLight};

    :hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`

const IconButton = styled.button`
  padding: 0.65rem;

  outline: none;
  border: none;

  background-color: transparent;

  :hover {
    svg {
      color: ${({ theme }) => theme.primaryLight};
    }
  }
`

interface Props {
  editor: Editor
  options: ActionMenuOption[]
}

export type ActionMenuOption = 'heading' | 'bold' | 'italic' | 'link' | 'document' | 'list' | 'ordered-list' | 'align' | 'underline'

export const ActionMenu = ({ editor, options }: Props) => {
  const [showDocumentModal, setShowDocumentModal] = useState(false)

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    if (url === null) {
      return
    }

    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run()

  }, [editor])

  const selected = () => {
    if (editor?.isActive('paragraph')) return 'p'
    if (editor?.isActive('heading', { level: 1 })) return 'h1'
    if (editor?.isActive('heading', { level: 2 })) return 'h2'
    if (editor?.isActive('heading', { level: 3 })) return 'h3'
    if (editor?.isActive('heading', { level: 4 })) return 'h4'
    if (editor?.isActive('heading', { level: 5 })) return 'h5'
    if (editor?.isActive('heading', { level: 6 })) return 'h6'

    return 'p'
  }

  return (
    <>
      <ActionContainer>
        {options.includes('heading') && (
          <Select
            tabIndex={-1}
            onChange={(e) => {
              switch (e.currentTarget.value) {
                case 'p':
                  editor?.chain().focus().setParagraph().run()
                  return
                case 'h1':
                  editor?.chain().focus().setHeading({ level: 1 }).run()
                  return
                case 'h2':
                  editor?.chain().focus().setHeading({ level: 2 }).run()
                  return
                case 'h3':
                  editor?.chain().focus().setHeading({ level: 3 }).run()
                  return
                case 'h4':
                  editor?.chain().focus().setHeading({ level: 4 }).run()
                  return
                case 'h5':
                  editor?.chain().focus().setHeading({ level: 5 }).run()
                  return
                case 'h6':
                  editor?.chain().focus().setHeading({ level: 6 }).run()
                  return
              }
            }}
            value={selected()}
          >
            <option value="p">Paragraf</option>
            <option value="h2">Rubrik</option>
          </Select>
        )}
        {options.includes('bold') && (
          <IconButton onClick={() => editor?.chain().focus().toggleBold().run()}>
            <BoldIcon
              tabIndex={-1}
              data-tip="Fetstilt"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive('bold') ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('underline') && (
          <IconButton onClick={() => editor?.chain().focus().toggleUnderline().run()}>
            <UnderlineIcon
              tabIndex={-1}
              data-tip="Understrykning"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive('underline') ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('align') && (
          <IconButton onClick={() => editor?.chain().focus().setTextAlign('left').run()}>
            <AlignLeft
              tabIndex={-1}
              data-tip="Vänsterjusterat"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive({ textAlign: 'left' }) ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('align') && (
          <IconButton onClick={() => editor?.chain().focus().setTextAlign('center').run()}>
            <AlignCenter
              tabIndex={-1}
              data-tip="Centerjusterat"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive({ textAlign: 'center' }) ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('align') && (
          <IconButton onClick={() => editor?.chain().focus().setTextAlign('right').run()}>
            <AlignRight
              tabIndex={-1}
              data-tip="Högerjusterat"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive({ textAlign: 'right' }) ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('italic') && (
          <IconButton onClick={() => editor?.chain().focus().toggleItalic().run()}>
            <ItalicIcon
              tabIndex={-1}
              data-tip="Kursivt"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive('italic') ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('list') && (
          <IconButton onClick={() => editor?.chain().focus().toggleBulletList().run()}>
            <ListIcon
              tabIndex={-1}
              data-tip="Lista"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive('bulletList') ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('ordered-list') && (
          <IconButton onClick={() => editor?.chain().focus().toggleOrderedList().run()}>
            <OrderedListIcon
              tabIndex={-1}
              data-tip="Ordnad lista"
              onMouseDown={(e) => e.preventDefault()}
              className={editor?.isActive('orderedList') ? 'active' : ''}
            />
          </IconButton>
        )}
        {options.includes('link') && (
          <IconButton onClick={setLink}>
            <LinkIcon
              tabIndex={-1}
              data-tip="Länk"
              onMouseDown={(e) => e.preventDefault()}
              className={
                editor?.isActive('link') && !editor?.getAttributes('link').href.includes('digitaloceanspaces')
                  ? 'active'
                  : ''
              }
            />
          </IconButton>
        )}
        {options.includes('document') && (
          <IconButton
            onClick={() => {
              if (editor?.isActive('link') && editor?.getAttributes('link').href.includes('digitaloceanspaces')) {
                editor?.chain().focus().extendMarkRange('link').unsetLink().run()
                return
              }
              setShowDocumentModal(true)
            }}
          >
            <FileTextIcon
              tabIndex={-1}
              data-tip="Dokument"
              onMouseDown={(e) => e.preventDefault()}
              className={
                editor?.isActive('link') && editor?.getAttributes('link').href.includes('digitaloceanspaces')
                  ? 'active'
                  : ''
              }
            />
          </IconButton>
        )}
      </ActionContainer>

      <SelectDocumentModal
        isOpen={showDocumentModal}
        onRequestClose={() => setShowDocumentModal(false)}
        onSelect={(url, displayName) => {
          if (url && editor) {
            editor
              ?.chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: url })
              .command(({ tr }) => {
                if (tr.selection.empty) {
                  tr.insertText(displayName)
                  return true
                }

                const text = editor.state.doc.textBetween(tr.selection.from, tr.selection.to, ' ')
                tr.insertText(text)

                return true
              })
              .unsetMark('link')
              .insertContent(' ')
              .focus()
              .run()
          }
        }}
      />
    </>
  )
}
