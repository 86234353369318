'use client'
import styled from '@emotion/styled'

export const EditorBase = styled.div`
  cursor: text;

  .ProseMirror p.is-editor-empty:first-of-type::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror p {
    margin: 0;
  }

  .ProseMirror:focus {
    outline: none;
  }

  .ProseMirror {
    overflow-y: auto;

    padding-left: calc(1rem - 4px);

    > * + * {
      margin-top: 0.75em;
    }

    ul,
    ol {
      padding: 0 1rem;
      margin-left: 1.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: 'JetBrainsMono', monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;

      :active,
      :focus {
        border: 2px solid ${({ theme }) => theme.primaryLight};
      }

      border: 2px solid transparent;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    u {
      text-decoration: underline;
    }

    p:empty:before {
      content: ' ';
      white-space: pre;
    }
  }

  :focus {
    outline-color: ${({ theme }) => theme.primaryLight};
  }
`
