import styled from '@emotion/styled'
import React, { ButtonHTMLAttributes } from 'react'
import { BounceLoader } from 'react-spinners'
import { Theme } from '@emotion/react'

type ButtonStyleProp = 'backgroundColor' | 'color' | 'hoverBackgroundColor' | 'hoverColor'
type ButtonVariant = 'primary' | 'secondary' | 'tertiary'
type ButtonState = 'disabled' | 'danger' | 'default'

const ButtonColorMap = (theme: Theme): Record<ButtonVariant, Record<ButtonState, Record<ButtonStyleProp, string>>> => ({
  primary: {
    default: {
      backgroundColor: theme.primary,
      hoverBackgroundColor: theme.primaryLight,
      color: theme.onPrimary,
      hoverColor: theme.onPrimary,
    },
    disabled: {
      backgroundColor: theme.onBackgroundLighter,
      hoverBackgroundColor: theme.onBackgroundLighter,
      color: theme.onBackgroundLight,
      hoverColor: theme.onBackgroundLight,
    },
    danger: {
      backgroundColor: theme.error,
      hoverBackgroundColor: theme.errorLight,
      color: 'white',
      hoverColor: 'white',
    },
  },
  secondary: {
    default: {
      backgroundColor: 'transparent',
      hoverBackgroundColor: 'transparent',
      color: theme.onBackground,
      hoverColor: theme.onBackgroundLight,
    },
    disabled: {
      backgroundColor: 'transparent',
      hoverBackgroundColor: 'transparent',
      color: theme.onBackgroundLight,
      hoverColor: theme.onBackgroundLight,
    },
    danger: {
      backgroundColor: 'transparent',
      hoverBackgroundColor: 'transparent',
      color: theme.error,
      hoverColor: theme.errorLight,
    },
  },
  tertiary: {
    default: {
      backgroundColor: theme.primaryLighter,
      hoverBackgroundColor: theme.primaryLighter,
      color: theme.primary,
      hoverColor: theme.primaryLight,
    },
    disabled: {
      backgroundColor: theme.onBackgroundLighter,
      hoverBackgroundColor: theme.onBackgroundLighter,
      color: theme.onBackgroundLight,
      hoverColor: theme.onBackgroundLight,
    },
    danger: {
      backgroundColor: theme.errorLightest,
      hoverBackgroundColor: theme.errorLightest,
      color: theme.error,
      hoverColor: theme.errorLight,
    },
  },
})

const ButtonBase = styled.button<{ disabled?: boolean; variant?: ButtonVariant; danger?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background-color: ${({ theme, disabled, variant = 'primary', danger }) => {
    const map = ButtonColorMap(theme)[variant]

    if (disabled) {
      return map['disabled'].backgroundColor
    }

    if (danger) {
      return map['danger'].backgroundColor
    }

    return map['default'].backgroundColor
  }};

  color: ${({ theme, disabled, variant = 'primary', danger }) => {
    const map = ButtonColorMap(theme)[variant]

    if (disabled) {
      return map['disabled'].color
    }

    if (danger) {
      return map['danger'].color
    }

    return map['default'].color
  }};

  padding: 1rem 2rem;
  font-size: 1rem;

  :hover {
    background-color: ${({ theme, disabled, variant = 'primary', danger }) => {
      const map = ButtonColorMap(theme)[variant]

      if (disabled) {
        return map['disabled'].hoverBackgroundColor
      }

      if (danger) {
        return map['danger'].hoverBackgroundColor
      }

      return map['default'].hoverBackgroundColor
    }};

    color: ${({ theme, disabled, variant = 'primary', danger }) => {
      const map = ButtonColorMap(theme)[variant]

      if (disabled) {
        return map['disabled'].hoverColor
      }

      if (danger) {
        return map['danger'].hoverColor
      }

      return map['default'].hoverColor
    }};
  }
`

interface ButtonProps {
  loading?: boolean
  disabled?: boolean
  variant?: ButtonVariant
  danger?: boolean
}

export const Button: React.FC<ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = ({
  loading,
  children,
  disabled,
  ...props
}) => {
  return (
    <ButtonBase disabled={loading || disabled} {...props}>
      {loading && (
        <div style={{ marginLeft: '-1.25rem', marginRight: '0.5rem' }}>
          <BounceLoader size="1rem" color="white" />
        </div>
      )}
      <div>{children}</div>
    </ButtonBase>
  )
}
