import Modal from 'react-modal'
import './BaseModal.css'

import { useTheme } from '@emotion/react'

interface Props {
  isOpen: boolean
  onRequestClose?: (() => void) | null
  children: React.ReactNode
}

export const BaseModal = ({ isOpen, onRequestClose, children }: Props) => {
  const theme = useTheme()
  return (
    <Modal
      isOpen={isOpen}
      closeTimeoutMS={200}
      onRequestClose={onRequestClose ?? undefined}
      appElement={document.getElementById('root') || undefined}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: 0,
          border: '1px solid ' + theme.onBackgroundLighter,
          zIndex: 1002,
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.15)',
          zIndex: 1001,
        },
      }}
    >
      {children}
    </Modal>
  )
}
