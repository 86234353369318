import styled from '@emotion/styled'
import React from 'react'
import { useContextPopup } from '@common/components/ContextPopup'
import { linkSharp, trashSharp, unlinkSharp } from 'ionicons/icons'
import { DEFAULT_IMAGE_URL } from '@common/features/blocks/HeadlineImageBlock/HeadlineImageBlock'
import { IonIcon } from '@ionic/react'

interface Props {
  editable: boolean
  linkTo?: string | null
  src: string
  onShowModal: () => void
  onClearImage: () => void
  onUpdateLink?: () => void
  onClearLink?: () => void
  className?: string
}

export const EditableImage = ({
  src,
  linkTo,
  editable,
  onShowModal,
  onClearImage,
  onUpdateLink,
  onClearLink,
  className,
}: Props) => {
  const { showPopup, hidePopup } = useContextPopup()

  const handleShowPopup = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (!editable) {
      return
    }

    const items = []

    if (!linkTo && onUpdateLink) {
      items.push({
        icon: linkSharp,
        text: 'Gör till länk',
        onClick: () => {
          onUpdateLink()
          hidePopup()
        },
      })
    } else {
      if (onUpdateLink) {
        items.push({
          icon: linkSharp,
          text: 'Ändra länk',
          onClick: () => {
            onUpdateLink()
            hidePopup()
          },
        })
      }
      if (onClearLink) {
        items.push({
          icon: unlinkSharp,
          text: 'Ta bort länk',
          onClick: () => {
            onClearLink()
            hidePopup()
          },
        })
      }
    }

    if (!src || src !== DEFAULT_IMAGE_URL) {
      items.push({
        icon: trashSharp,
        text: 'Ta bort bild',
        onClick: () => {
          onClearImage()
          hidePopup()
        },
      })
    }

    showPopup(e, items)
  }

  return (
    <Container>
      <Image
        src={src}
        alt=""
        clickable={editable || !!linkTo}
        className={className}
        onContextMenu={(e) => {
          if (!editable) {
            return
          }

          e.preventDefault()

          handleShowPopup(e)
        }}
        onClick={() => {
          if (editable) {
            onShowModal()
            return
          }

          if (linkTo) {
            let trimmedLink = linkTo

            if (!linkTo.includes('http://') && !linkTo.includes('https://') && !linkTo.includes('//')) {
              trimmedLink = '//' + linkTo
            }

            window.open(trimmedLink, '_blank')
          }
        }}
      />
      {!!linkTo && editable && onUpdateLink && (
        <IconButton
          onClick={() => {
            onUpdateLink()
          }}
        >
          <IonIcon className="icon" icon={linkSharp} />
        </IconButton>
      )}
    </Container>
  )
}

const Image = styled.img<{ clickable: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: ${({ clickable }) => (clickable ? 0.8 : 1)};
  }
`

const IconButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;

  -webkit-appearance: none;

  outline: none;
  border: none;

  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.onPrimary};

  width: 3rem;
  height: 3rem;

  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.primaryLight};
  }
`

const Container = styled.div`
  display: block;
  position: relative;
`
