import styled from '@emotion/styled'
import React, { HTMLAttributes, ReactNode } from 'react'
import { IonIcon } from '@ionic/react'
import { warningSharp } from 'ionicons/icons'
import { Skeleton } from './Skeleton'

const InputBase = styled.input<{ disabled?: boolean }>`
  width: 100%;
  background-color: ${({ theme, disabled }) => (disabled ? theme.onBackgroundLightest : theme.background)};
  border: 1px solid ${({ theme, disabled }) => (disabled ? theme.onBackgroundLightest : theme.onBackgroundLighter)};
  padding: 0.8rem 0.7rem;

  color: ${({ theme }) => theme.onBackground};

  :focus {
    outline-color: ${({ theme }) => theme.primaryLight};
  }
`

export const Label = styled.label`
  display: inline-block;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.onBackgroundSemiLight};
  background-color: transparent;

  margin-bottom: 0.35rem;
`

const Error = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.25rem;

  color: ${({ theme }) => theme.error};
  font-size: 0.75rem;
  margin-top: 0.25rem;

  .icon {
    font-size: 0.65rem;
  }
`

const Subtext = styled.div`
  color: ${({ theme }) => theme.onBackgroundLight};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`

export const ErrorText: React.FC<{ error: string } & HTMLAttributes<HTMLDivElement>> = ({ error, ...props }) => {
  if (!error) {
    return null
  }

  return (
    <Error {...props}>
      <IonIcon icon={warningSharp} className="icon" />
      {error}
    </Error>
  )
}

interface InputProps {
  label?: string
  error?: string
  subtext?: ReactNode
  required?: boolean
  skeleton?: boolean
}

export const Input = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement> & InputProps>(
  ({ label, error, subtext, skeleton, disabled, ...props }, ref) => {
    return (
      <div>
        {!!label && (
          <div>
            <Label>
              <Skeleton display="inline-block" active={skeleton}>
                {label}
                {!!props?.required && (
                  <span style={{ color: 'crimson', fontSize: '0.9rem', opacity: skeleton ? 0 : 1 }}> *</span>
                )}
              </Skeleton>
            </Label>
          </div>
        )}
        <Skeleton display="block" active={skeleton}>
          <InputBase ref={ref} disabled={skeleton || disabled} {...props} />
        </Skeleton>
        {error && <ErrorText error={error} />}
        {subtext && <Subtext>{subtext}</Subtext>}
      </div>
    )
  },
)

Input.displayName = 'Input'
