import { gql } from '@apollo/client'
import { useUpdateBlockPayloadMutation } from '@graphql/generated'
import { useDebounce } from '@common/hooks/use-debounce'
import { useCallback, useEffect, useState } from 'react'

gql`
  mutation UpdateBlockPayload($blockId: ID!, $payload: String!) {
    websiteBlockUpdate(input: { blockId: $blockId, payload: $payload }) {
      ...BlockFragment
    }
  }
`

interface Props {
  blockId: string
  debounce?: boolean
  active?: boolean
}

type JSON = Record<string, object | string | number | null>

export const useUpdateBlock = ({ blockId, debounce, active }: Props) => {
  const [updateBlock] = useUpdateBlockPayloadMutation()
  const [payload, setPayload] = useState<JSON>({})
  const debouncedPayload = useDebounce(payload, debounce ? 500 : 0)

  useEffect(() => {
    if (!active) return

    void updateBlock({
      variables: {
        blockId,
        payload: JSON.stringify(debouncedPayload),
      },
      refetchQueries: ['WebsiteBlocks'],
    })
  }, [blockId, debouncedPayload, active, updateBlock])

  const handleUpdateBlock = useCallback(async (incomingPayload: JSON) => {
    setPayload((prevState) => ({ ...prevState, ...incomingPayload }))
  }, []);

  return {
    updateBlock: handleUpdateBlock,
  }
}
