'use client'
import { useUpdateBlock } from '@common/features/block-editor/hooks/use-update-block'
import { TipTapField } from '@common/features/blocks/common/TipTapField/TipTapField'
import { Props } from './HeadlineBlock'
import { Container } from './styles'

export const Edit = ({ subtitle, headline, id }: Props) => {
  const { updateBlock } = useUpdateBlock({ blockId: id, active: true })

  return (
    <>
      <Container>
        <div className="content">
          <TipTapField
            editable={true}
            className="headline"
            defaultContent={headline}
            onChange={(text) => updateBlock({ headline: text })}
            placeholder="Rubrik"
            richTextOptions={['align']}
          />
          <TipTapField
            editable={true}
            className="subtitle"
            defaultContent={subtitle}
            onChange={(text) => updateBlock({ subtitle: text })}
            placeholder="Underrubrik"
            richTextOptions={[
              'heading',
              'bold',
              'underline',
              'italic',
              'link',
              'document',
              'list',
              'ordered-list',
              'align',
            ]}
          />
        </div>
      </Container>
    </>
  )
}
