'use client'
import styled from '@emotion/styled'
import { TipTapField } from '@common/features/blocks/common/TipTapField/TipTapField'
import { useState } from 'react'
import { EditableImage } from '@common/features/blocks/common/EditableImage'
import { SiteResponsivePadding } from '@app/site/common/components/SiteResponsivePadding'
import toast from 'react-hot-toast'
import { useUpdateBlock } from '@common/features/block-editor/hooks/use-update-block'
import { SelectImageModal } from '../../block-editor/SelectImageModal'

interface Props {
  id: string
  imageUrl: string
  headline: string
  subtitle: string
  imageLink: string

  editable: boolean
}

export const DEFAULT_IMAGE_URL = '/samples/sample_buildings.webp'

export const HeadlineImageBlock = ({ id, imageUrl, headline, subtitle, imageLink, editable }: Props) => {
  const [showImageModal, setShowImageModal] = useState(false)

  const defaultImageUrl = imageUrl || DEFAULT_IMAGE_URL

  const { updateBlock } = useUpdateBlock({ blockId: id, active: editable })

  const handleClearImage = () => {
    void updateBlock({ imageUrl: '' })
  }

  const handleUpdateLink = () => {
    setTimeout(() => {
      const link = window.prompt('Länk till', imageLink || '')

      if (link === null) {
        return
      }

      void toast.promise(updateBlock({ imageLink: link }), {
        loading: 'Uppdaterar länk',
        success: 'Länk uppdaterad',
        error: 'Kunde inte uppdatera länk',
      })
    }, 10)
  }

  const handleClearLink = () => {
    setTimeout(() => {
      void toast.promise(updateBlock({ imageLink: '' }), {
        loading: 'Tar bort länk',
        success: 'Länk borttagen',
        error: 'Kunde inte ta bort länk',
      })
    }, 10)
  }

  return (
    <>
      <Container>
        <div className="content">
          <TipTapField
            editable={editable}
            className="headline"
            defaultContent={headline}
            onChange={(text) => updateBlock({ headline: text })}
            placeholder="Rubrik"
            richTextOptions={[]}
          />
          <TipTapField
            editable={editable}
            className="subtitle"
            defaultContent={subtitle}
            onChange={(text) => updateBlock({ subtitle: text })}
            placeholder="Underrubrik"
            richTextOptions={['heading', 'bold', 'underline', 'italic', 'link', 'document', 'list', 'ordered-list']}
          />
        </div>
        <EditableImage
          src={defaultImageUrl}
          editable={editable}
          onShowModal={() => setShowImageModal(true)}
          onUpdateLink={handleUpdateLink}
          onClearLink={handleClearLink}
          onClearImage={handleClearImage}
          linkTo={imageLink}
        />
      </Container>
      {editable && (
        <SelectImageModal
          isOpen={showImageModal}
          onRequestClose={() => setShowImageModal(false)}
          onSelect={(url) => updateBlock({ imageUrl: url })}
        />
      )}
    </>
  )
}

const Container = styled.div`
  ${SiteResponsivePadding};

  margin-block: 0;

  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  img {
    flex: 2 1 20rem;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    overflow: hidden;
    object-fit: cover;

    max-height: 30rem;

    @media (max-width: 1500px) {
      max-height: 20rem;
    }
    
    border-radius: 0.15rem;
  }

  > div {
    padding-right: 6rem;
    padding-top: 8rem;
    padding-bottom: 8rem;

    @media (max-width: 1000px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
      padding-right: 0;
    }

    @media (max-width: 800px) {
      padding-top: 2rem;
      padding-bottom: 2rem;

      padding-right: 0;
    }

    > .headline {
      font-size: 3.25rem;
      font-weight: 600;
      color: ${({ theme }) => theme.onBackground};

      line-height: 1.15;

      @media (max-width: 1000px) {
        font-size: 1.8rem;
      }
    }

    > .subtitle {
      margin-top: 1rem;
      font-size: 1.25rem;

      @media (max-width: 800px) {
        font-size: 1.15rem;
      }

      line-height: 1.35;

      color: ${({ theme }) => theme.onBackgroundSemiLight};
    }
  }

  > .content {
    flex: 3 1 30rem;
  }
`
