import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { ActionMenuOption } from '@common/features/blocks/common/ActionMenu'
import { TextAlign } from '@tiptap/extension-text-align'
import { Underline } from '@tiptap/extension-underline'
import { CustomLink } from '../CustomLink'
import { EditableSection } from '@common/features/blocks/common/TipTapField/EditableSection'
import { ReadOnlySection } from '@common/features/blocks/common/TipTapField/ReadOnlySection'

interface SimpleEditorProps {
  onChange?: (richText: string) => void
  defaultContent?: string
  placeholder?: string
  className?: string
  editable?: boolean
  richTextOptions?: ActionMenuOption[]
}

export const restoreContent = (storedContent: string) => {
  if (!storedContent) {
    return ''
  }

  try {
    return JSON.parse(storedContent)
  } catch (e) {
    console.warn(e)
    return storedContent
  }
}

export const TipTapField = ({
  onChange,
  defaultContent = '',
  placeholder,
  className,
  editable = false,
  richTextOptions,
}: SimpleEditorProps) => {
  const extensions = [
    StarterKit.configure({
      heading: richTextOptions?.includes('heading')
        ? {
            levels: [2],
          }
        : undefined,
      bold: richTextOptions?.includes('bold') ? undefined : false,
      italic: richTextOptions?.includes('italic') ? undefined : false,
      bulletList: richTextOptions?.includes('list') ? undefined : false,
      orderedList: richTextOptions?.includes('ordered-list') ? undefined : false,
    }),
    Placeholder.configure({ placeholder }),
    ...(!richTextOptions?.includes('link')
      ? []
      : [
          CustomLink.configure({
            openOnClick: !editable,
            tooltip: !!editable,
          }),
        ]),
    ...(richTextOptions?.includes('align')
      ? [
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
        ]
      : []),
    ...(richTextOptions?.includes('underline') ? [Underline] : []),
  ]

  if (!editable) {
    return <ReadOnlySection content={defaultContent} extensions={extensions} className={className} />
  }

  return (
    <EditableSection
      extensions={extensions}
      onChange={onChange}
      defaultContent={defaultContent}
      className={className}
      richTextOptions={richTextOptions}
    />
  )
}
