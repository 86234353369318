import { restoreContent } from '@common/features/blocks/common/TipTapField/TipTapField'
import { Extensions } from '@tiptap/react'
import { generateHTML } from '@tiptap/html'
import { EditorBase } from '@common/features/blocks/common/TipTapField/styles'

interface Props {
  content: string
  className?: string
  extensions: Extensions
}

export const ReadOnlySection = ({ extensions, content, className }: Props) => {
  if (!content) {
    return null
  }

  const parsedContent = restoreContent(content)

  return (
    <>
      <EditorBase className={className}>
        {typeof parsedContent === 'string' ? (
          parsedContent
        ) : (
          <div className="ProseMirror" dangerouslySetInnerHTML={{ __html: generateHTML(parsedContent, extensions) }} />
        )}
      </EditorBase>
    </>
  )
}
