import styled from '@emotion/styled'
import { forwardRef } from 'react';

import { ErrorText, Label } from './Input'
import { Skeleton } from './Skeleton'
import { IoCaretDownSharp } from 'react-icons/io5'

const SelectBase = styled.select<{ disabled?: boolean; isPlaceholder?: boolean }>`
  -webkit-appearance: none;
  
  width: 100%;
  background-color: ${({ theme, disabled }) => (disabled ? theme.onBackgroundLightest : theme.background)};
  border: 1px solid ${({ theme, disabled }) => (!disabled ? theme.onBackgroundLighter : theme.onBackgroundLightest)};
  padding: 0.8rem 0.7rem;
  border-radius: 0;

  color: ${({ theme, isPlaceholder }) => (isPlaceholder ? theme.onBackgroundLighter : theme.onBackground)};

  :focus {
    outline-color: ${({ theme }) => theme.primaryLight};
  }
`

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .select-icon {
    position: absolute;
    right: 0.5rem;
    top: 48%;
    transform: translateY(-50%);
    
    scale: 0.8;
  }
`

interface SelectProps {
  label?: string
  error?: string
  required?: boolean
  isPlaceholder?: boolean
  skeleton?: boolean
}

export const Select = forwardRef<HTMLSelectElement, React.SelectHTMLAttributes<HTMLSelectElement> & SelectProps>(
  ({ label, error, skeleton, isPlaceholder, ...props }, ref) => {
    return (
      <>
        <div>
          {!!label && (
            <Label>
              <Skeleton display="inline-block" active={skeleton}>
                {label}
                {!!props?.required && <span style={{ color: 'crimson', fontSize: '0.9rem' }}> *</span>}
              </Skeleton>
            </Label>
          )}
          <Skeleton display="block" active={skeleton}>
            <SelectContainer>
            <SelectBase ref={ref} {...props} isPlaceholder={isPlaceholder} />
            <IoCaretDownSharp className="select-icon" /></SelectContainer>
          </Skeleton>
          {error && <ErrorText error={error} />}
        </div>
      </>
    )
  },
)

Select.displayName = 'Select'
