import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'

export const shimmer = keyframes`
    0% {
      opacity: 1.0;
    }
    50% {
      opacity: 0.60;
    }
    100% {
      opacity: 1.0;
    }
`

export const Skeleton = styled.div<{ display?: string; active?: boolean; delay?: number }>`
  display: ${({ display }) => display};
  position: relative;

  ${({ active, theme, delay = 0 }) =>
    active &&
    css`
      &::before {
        z-index: 2;
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        background-color: ${theme.background};

        opacity: 1;

        width: 100%;
        height: 100%;
      }

      &::after {
        z-index: 3;
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        background-color: ${theme.onBackgroundLightest};

        opacity: 1;
        animation: ${shimmer} 2s infinite;
        animation-delay: ${delay}s;

        width: 100%;
        height: 100%;
      }
    `}
`
