import styled from '@emotion/styled'
import { ReactNode } from 'react'

const Title = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.onBackground};
  margin-bottom: 0.25rem;
`

const Subtitle = styled.p`
  margin-top: 0.5rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.onBackgroundSemiLight};

  max-width: 30rem;
`

type HeadlineSize = 'standard' | 'small'

export const Headline: React.FC<{ text: string; subtext?: ReactNode; size?: HeadlineSize }> = ({
  text,
  subtext,
  size = 'standard',
}) => {
  return (
    <div>
      <Title style={{ fontSize: size === 'small' ? '1.5rem' : undefined }}>{text}</Title>
      {subtext && <Subtitle>{subtext}</Subtitle>}
    </div>
  )
}
