import { FileDropzone, ImageFileTypes } from '@common/components/FileDropzone'
import React, { useEffect, useState } from 'react'
import { useTheme } from '@emotion/react'
import { Button } from '@common/components/Button'
import styled from '@emotion/styled'
import toast from 'react-hot-toast'
import { FileNamespace, useCreatePublicCopyMutation, useCreateUnsortedCopyMutation } from '@graphql/generated'
import { BaseModal } from '@common/components/BaseModal'
import { useUploadedFiles } from '@common/features/files/use-uploaded-files'
import { useFileExplorer } from '@common/features/files/use-file-explorer'
import { SelectFileModal } from './SelectFileModal/SelectFileModal'

const SplitContainer = styled.div`
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`

export const SelectImageModal: React.FC<{
  isOpen: boolean
  onRequestClose: () => void
  onSelect: (url: string) => void
}> = ({ isOpen, onRequestClose, onSelect }) => {
  const [showArchive, setShowArchive] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const theme = useTheme()

  const { uploadFiles } = useUploadedFiles(FileNamespace.Public)
  const { uploadedFiles } = useFileExplorer('', FileNamespace.General)

  const imageFiles =
    uploadedFiles?.filter((file) => {
      if (!file.key.includes('.')) return false

      const fileExtension = file.key.split('.').pop()

      if (!fileExtension) return false

      const candidates = Object.values(ImageFileTypes).flat(1)

      return candidates.includes('.' + fileExtension)
    }) ?? []

  const [createPublicCopy] = useCreatePublicCopyMutation()
  const [createUnsortedCopy] = useCreateUnsortedCopyMutation()

  const handleSelectImage = () => {
    if (!imageUrl) return

    onSelect(imageUrl)
    onRequestClose()
    setImageUrl(null)
  }

  useEffect(() => {
    if (!imageUrl) return

    handleSelectImage()
  }, [imageUrl])

  return (
    <>
      <BaseModal isOpen={isOpen} onRequestClose={onRequestClose}>
        <div>
          <h2>Välj en bild</h2>
          <SplitContainer>
            <div>
              <Button variant="tertiary" style={{ width: '100%' }} onClick={() => setShowArchive(true)}>
                Välj från dokumentarkiv
              </Button>
            </div>
            <div>
              <div style={{ fontSize: '0.75rem', color: theme.onBackgroundSemiLight, textAlign: 'center' }}>eller</div>
            </div>
            <div>
              <FileDropzone
                label="Släpp en bild eller klicka här för att ladda upp"
                accept={ImageFileTypes}
                onUpload={(acceptedFiles) => {
                  toast.promise(uploadFiles('', acceptedFiles), {
                    loading: 'Laddar upp bilden',
                    success: (files) => {
                      setImageUrl(files?.[0]?.url ?? null)
                      const key = files?.[0]?.key

                      if (!key) return 'Bilden uppladdad'
                      void createUnsortedCopy({ variables: { key } })

                      return 'Bilden uppladdad'
                    },
                    error: 'Kunde inte ladda upp bilden',
                  })
                }}
                height="15rem"
              />
            </div>
          </SplitContainer>
        </div>
      </BaseModal>
      <SelectFileModal
        onSelect={(key) =>
          toast.promise(createPublicCopy({ variables: { key } }), {
            loading: 'Laddar upp bilden',
            success: (result) => {
              setImageUrl(result.data?.fileCreatePublicCopy?.file?.url ?? null)
              return 'Bilden uppladdad'
            },
            error: 'Kunde inte ladda upp bilden',
          })
        }
        onRequestClose={() => setShowArchive(false)}
        isOpen={showArchive}
        keys={imageFiles.map((file) => file.key)}
        placeholder="Inga bilder hittades"
      />
    </>
  )
}
