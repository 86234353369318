'use client'
import { ActionMenu, ActionMenuOption } from '@common/features/blocks/common/ActionMenu'
import { restoreContent } from '@common/features/blocks/common/TipTapField/TipTapField'
import styled from '@emotion/styled'
import { BubbleMenu, EditorContent, Extensions, useEditor } from '@tiptap/react'
import ReactTooltip from 'react-tooltip'
import { EditorBase } from '@common/features/blocks/common/TipTapField/styles'
import { useEffect } from 'react'

interface Props {
  onChange?: (richText: string) => void
  defaultContent?: string
  placeholder?: string
  className?: string
  editable?: boolean
  extensions?: Extensions
  richTextOptions?: ActionMenuOption[]
}

export const EditableSection = ({ extensions, defaultContent = '', onChange, richTextOptions, className }: Props) => {
  const editor = useEditor({
    editable: true,
    extensions,
    content: restoreContent(defaultContent),
    onUpdate: ({ editor }) => {
      const json = editor.getJSON()

      onChange?.(JSON.stringify(json))
    },
  })

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <>
      <EditorBase className={className} onClick={() => editor?.chain().focus().run()}>
        <EditorContent editor={editor} />
      </EditorBase>
      <TooltipContainer>
        <ReactTooltip
          effect="solid"
          place="top"
          delayShow={250}
          delayHide={100}
          multiline={false}
          className="tooltip"
          html={true}
          getContent={(content) => {
            // Hacky way, but it works
            let resultingText = content?.toString() || ''

            if (resultingText.includes('__public')) {
              resultingText = resultingText.split('__public')[1]
              if (resultingText[0] === '/') {
                resultingText = resultingText.substring(1)
              }
              resultingText = resultingText.replaceAll('/', ' → ')
            }

            if (!content) {
              return ''
            }

            return `<a href="${content}" target="_blank" style="color: white;">${resultingText}</a>`
          }}
        />
      </TooltipContainer>
      {editor && !!richTextOptions?.length && (
        <BubbleMenu
          editor={editor}
          tippyOptions={{
            duration: 100,
            maxWidth: 500,
            moveTransition: 'transform 0.2s cubic-bezier(.3,.8,.36,.84)',
          }}
        >
          <ActionMenu editor={editor} options={richTextOptions} />
        </BubbleMenu>
      )}
    </>
  )
}

const TooltipContainer = styled.div`
  .__react_component_tooltip {
    pointer-events: auto !important;
  }

  .tooltip-link {
    color: ${({ theme }) => theme.onPrimary} !important;
  }

  .tooltip {
    font-size: 1.1rem;
    padding: 0.6rem 1rem;
  }
`
