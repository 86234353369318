'use client'
import styled from '@emotion/styled'
import { SiteResponsivePadding } from '@app/site/common/components/SiteResponsivePadding'
import { TipTapField } from '@common/features/blocks/common/TipTapField/TipTapField'
import { useState } from 'react'
import { EditableImage } from '@common/features/blocks/common/EditableImage'
import { useUpdateBlock } from '@common/features/block-editor/hooks/use-update-block'
import { SelectImageModal } from '../../block-editor/SelectImageModal'

interface Props {
  id: string

  firstImageUrl: string
  firstCaption: string
  firstImageLink: string

  secondImageUrl: string
  secondCaption: string
  secondImageLink: string

  thirdImageUrl: string
  thirdCaption: string
  thirdImageLink: string

  editable: boolean
}

export const ImageCollectionBlock = ({
  id,
  editable,
  firstImageUrl,
  firstImageLink,
  secondImageUrl,
  secondImageLink,
  thirdImageUrl,
  thirdImageLink,
  thirdCaption,
  secondCaption,
  firstCaption,
}: Props) => {
  const nItems = [firstImageUrl, secondImageUrl, thirdImageUrl].filter((url) => !!url).length

  const { updateBlock } = useUpdateBlock({ blockId: id, active: editable })

  return (
    <Container nItems={nItems}>
      <ImageEntry
        imageUrl={firstImageUrl}
        imageLink={firstImageLink}
        caption={firstCaption}
        editable={editable}
        onChangeImageUrl={(imageUrl) => updateBlock({ firstImageUrl: imageUrl })}
        onChangeCaption={(caption) => updateBlock({ firstCaption: caption })}
        onChangeImageLink={(link) => updateBlock({ firstImageLink: link })}
      />
      <ImageEntry
        imageUrl={secondImageUrl}
        imageLink={secondImageLink}
        caption={secondCaption}
        editable={editable}
        onChangeImageUrl={(imageUrl) => updateBlock({ secondImageUrl: imageUrl })}
        onChangeCaption={(caption) => updateBlock({ secondCaption: caption })}
        onChangeImageLink={(link) => updateBlock({ secondImageLink: link })}
      />
      <ImageEntry
        imageUrl={thirdImageUrl}
        imageLink={thirdImageLink}
        caption={thirdCaption}
        editable={editable}
        onChangeImageUrl={(imageUrl) => updateBlock({ thirdImageUrl: imageUrl })}
        onChangeCaption={(caption) => updateBlock({ thirdCaption: caption })}
        onChangeImageLink={(link) => updateBlock({ thirdImageLink: link })}
      />
    </Container>
  )
}

interface ImageEntryProps {
  imageUrl: string
  imageLink: string
  caption: string

  onChangeCaption: (caption: string) => void
  onChangeImageUrl: (imageUrl: string) => void
  onChangeImageLink: (link: string) => void

  editable: boolean
}
const ImageEntry = ({
  editable,
  imageUrl,
  imageLink,
  caption,
  onChangeCaption,
  onChangeImageUrl,
  onChangeImageLink,
}: ImageEntryProps) => {
  const [showImageModal, setShowImageModal] = useState(false)
  const showPlaceholder = !imageUrl && editable

  const handleUpdateLink = () => {
    setTimeout(() => {
      const link = window.prompt('Länk till', imageLink || '')

      if (link === null) {
        return
      }

      onChangeImageLink(link)
    }, 10)
  }

  return (
    <>
      <div>
        {showPlaceholder ? (
          <button className="img-item placeholder-item" onClick={() => setShowImageModal(true)}>
            <span>+ Lägg till bild</span>
          </button>
        ) : (
          <EditableImage
            className="img-item"
            src={imageUrl}
            linkTo={imageLink}
            onUpdateLink={handleUpdateLink}
            onClearLink={() => onChangeImageLink('')}
            onClearImage={() => onChangeImageUrl('')}
            editable={editable}
            onShowModal={() => setShowImageModal(true)}
          />
        )}
        <TipTapField
          editable={editable}
          className="caption"
          defaultContent={caption}
          onChange={(text) => onChangeCaption(text)}
          placeholder="Undertext"
        />
      </div>
      {editable && (
        <SelectImageModal
          isOpen={showImageModal}
          onRequestClose={() => setShowImageModal(false)}
          onSelect={(url) => onChangeImageUrl(url)}
        />
      )}
    </>
  )
}

const Container = styled.div<{ nItems: number }>`
  ${SiteResponsivePadding};

  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  flex-wrap: wrap;

  ${({ nItems }) => (nItems < 3 ? `justify-content: center;` : `justify-content: space-between;`)};

  gap: 2rem;

  div {
    flex-basis: 400px;

    @media (max-width: 1500px) {
      flex-basis: 340px;
    }

    @media (max-width: 1400px) {
      flex-basis: 320px;
    }

    @media (max-width: 1300px) {
      flex-basis: 300px;
    }

    @media (max-width: 1200px) {
      flex-basis: 400px;
    }

    @media (max-width: 1050px) {
      flex-basis: 340px;
    }

    @media (max-width: 900px) {
      flex-basis: 300px;
    }

    @media (max-width: 800px) {
      flex-basis: 100%;
    }
  }

  div .img-item {
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;

    object-fit: cover;
    max-width: 100%;
    height: auto;
    vertical-align: middle;

    border-radius: 0.15rem;
  }

  div .placeholder-item {
    background-color: ${({ theme }) => theme.background};
    border: 2px dashed ${({ theme }) => theme.onBackgroundLighter};

    :hover {
      border: 2px dashed ${({ theme }) => theme.primary};
    }

    :hover span {
      color: ${({ theme }) => theme.primary};
    }

    cursor: pointer;
    width: 100%;

    & span {
      color: ${({ theme }) => theme.onBackgroundLighter};
      font-size: 1rem;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: none;

    aspect-ratio: 1;
  }

  div .extra {
    opacity: 0;
  }

  div .caption {
    display: block;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #717171;
  }
`
