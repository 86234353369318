/// <reference types="next-plugin-svgr/types/svg" />

import _BoldIcon from '@common/assets/icons/bold.svg'
import _ItalicIcon from '@common/assets/icons/italic.svg'
import _ListIcon from '@common/assets/icons/list.svg'
import _OrderedListIcon from '@common/assets/icons/ordered-list.svg'
import _FileTextIcon from '@common/assets/icons/file-text.svg'
import _LinkIcon from '@common/assets/icons/link.svg'
import _ImageIcon from '@common/assets/icons/image.svg'
import _AlignCenter from '@common/assets/icons/align-center.svg'
import _AlignJustify from '@common/assets/icons/align-justify.svg'
import _AlignLeft from '@common/assets/icons/align-left.svg'
import _AlignRight from '@common/assets/icons/align-right.svg'
import _UnderlineIcon from '@common/assets/icons/underline.svg'

export const BoldIcon = _BoldIcon
export const ItalicIcon = _ItalicIcon
export const FileTextIcon = _FileTextIcon
export const ListIcon = _ListIcon
export const OrderedListIcon = _OrderedListIcon
export const LinkIcon = _LinkIcon
export const ImageIcon = _ImageIcon
export const AlignCenter = _AlignCenter
export const AlignJustify = _AlignJustify
export const AlignLeft = _AlignLeft
export const AlignRight = _AlignRight
export const UnderlineIcon = _UnderlineIcon
