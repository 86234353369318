'use client'
import styled from '@emotion/styled'
import { TipTapField } from '@common/features/blocks/common/TipTapField/TipTapField'
import { SiteResponsivePadding } from '@app/site/common/components/SiteResponsivePadding'
import { BaseModal } from '@common/components/BaseModal'
import { Label } from '@common/components/Input'
import { useEffect, useState } from 'react'
import { useDebounce } from '@common/hooks/use-debounce'
import { Button } from '@common/components/Button'
import { IonIcon } from '@ionic/react'
import { imageSharp } from 'ionicons/icons'
import { useUpdateBlock } from '@common/features/block-editor/hooks/use-update-block'
import { SelectImageModal } from '../../block-editor/SelectImageModal'

interface Props {
  id: string

  headline: string
  body: string
  imageUrl: string
  backgroundColor: string
  textColor: string

  editable: boolean
  isModalVisible: boolean
  closeModal: (() => void) | null
}

export const BannerBlock = ({
  headline,
  body,
  imageUrl,
  backgroundColor: bgColor,
  textColor: txtColor,
  editable,
  isModalVisible,
  closeModal,
  id,
}: Props) => {
  const [showImageModal, setShowImageModal] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState(bgColor)
  const debouncedBackgroundColor = useDebounce(backgroundColor, 500)

  const [textColor, setTextColor] = useState(txtColor)
  const debouncedTextColor = useDebounce(textColor, 500)

  const { updateBlock } = useUpdateBlock({ blockId: id, active: editable })

  useEffect(() => {
    if (debouncedBackgroundColor && debouncedBackgroundColor !== bgColor) {
      void updateBlock({ backgroundColor: debouncedBackgroundColor })
    }

    if (debouncedTextColor && debouncedTextColor !== txtColor) {
      void updateBlock({ textColor: debouncedTextColor })
    }
  }, [bgColor, debouncedBackgroundColor, debouncedTextColor, txtColor, updateBlock])

  return (
    <>
      <Container backgroundColor={backgroundColor} textColor={textColor} imageUrl={imageUrl}>
        <div className="content">
          <TipTapField
            editable={editable}
            className="headline"
            defaultContent={headline}
            onChange={(text) => updateBlock({ headline: text })}
            placeholder="Rubrik"
            richTextOptions={['align']}
          />
          <TipTapField
            editable={editable}
            className="body"
            defaultContent={body}
            onChange={(text) => updateBlock({ body: text })}
            placeholder="Underrubrik"
            richTextOptions={['heading', 'bold', 'underline', 'italic', 'link', 'document', 'list', 'ordered-list', 'align']}
          />
        </div>
      </Container>
      <BaseModal isOpen={isModalVisible} onRequestClose={closeModal}>
        <h2>Banner</h2>
        <ConfigContainer>
          <div>
            <Label>Textfärg</Label>
            <input
              type="color"
              value={textColor}
              onChange={(e) => {
                setTextColor(e.target.value)
              }}
            />
          </div>
          <div>
            <Label>Bakgrundsfärg</Label>
            <input
              type="color"
              value={backgroundColor}
              onChange={(e) => {
                setBackgroundColor(e.target.value)
              }}
            />
          </div>
          <div>
            <Button variant="tertiary" onClick={() => setShowImageModal(true)}>
              <IonIcon icon={imageSharp} style={{ marginRight: '0.5rem', marginBottom: '-0.2rem' }} />
              Välj bakgrundsbild
            </Button>
          </div>
        </ConfigContainer>
      </BaseModal>
      {editable && (
        <SelectImageModal
          isOpen={showImageModal}
          onRequestClose={() => setShowImageModal(false)}
          onSelect={(url) => updateBlock({ imageUrl: url })}
        />
      )}
    </>
  )
}

const ConfigContainer = styled.div`
  display: flex;
  flex-direction: row;

  min-width: 20rem;

  margin-top: 0.75rem;

  > div {
    display: flex;
    flex-direction: column;
  }

  div + div {
    margin-left: 2rem;
  }
`

const Container = styled.div<{ backgroundColor: string; textColor: string; imageUrl: string }>`
  ${SiteResponsivePadding};

  ${({ imageUrl }) => imageUrl && `background-image: url(${imageUrl}); background-size: cover;`}
  color: ${({ textColor }) => textColor || '#ffffff'};
  background-color: ${({ backgroundColor }) => backgroundColor || '#091927'};

  display: flex;
  flex-wrap: wrap;

  > div {
    padding-top: 12rem;
    padding-bottom: 12rem;

    @media (max-width: 1000px) {
      padding-top: 9rem;
      padding-bottom: 9rem;
    }

    @media (max-width: 800px) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    ${({ imageUrl }) =>
      imageUrl &&
      ` 
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      `}
    > .headline {
      font-size: 3.5rem;
      font-weight: 600;

      line-height: 1.15;

      @media (max-width: 1000px) {
        font-size: 1.8rem;
      }
    }

    > .body {
      margin-top: 1rem;
      font-size: 1.15rem;

      @media (max-width: 800px) {
        max-width: 100%;
        font-size: 1.15rem;
      }

      line-height: 1.35;
    }
  }

  > .content {
    flex: 3 1 30rem;
  }
`
