'use client'
import { SiteResponsivePadding } from '@app/site/common/components/SiteResponsivePadding'
import styled from '@emotion/styled'
import { TipTapField } from '@common/features/blocks/common/TipTapField/TipTapField'
import { useUpdateBlock } from '@common/features/block-editor/hooks/use-update-block'

interface Props {
  id: string

  leftColumnTitle: string
  rightColumnTitle: string

  leftColumnBody: string
  rightColumnBody: string

  editable: boolean
}

export const TwoColumnBlock = ({ id, editable, leftColumnTitle, leftColumnBody, rightColumnBody }: Props) => {
  const { updateBlock } = useUpdateBlock({ blockId: id, active: editable })

  return (
    <Container>
      <TipTapField
        editable={editable}
        className="left-column-title"
        defaultContent={leftColumnTitle}
        onChange={(text) => updateBlock({ leftColumnTitle: text })}
        placeholder="Rubrik"
      />
      <div className="left-column">
        <TipTapField
          editable={editable}
          className="left-column-body"
          defaultContent={leftColumnBody}
          onChange={(text) => updateBlock({ leftColumnBody: text })}
          placeholder="Text"
          richTextOptions={['heading', 'bold', 'underline', 'italic', 'link', 'document', 'list', 'ordered-list']}
        />
      </div>
      <div className="right-column">
        <TipTapField
          editable={editable}
          className="right-column-body"
          defaultContent={rightColumnBody}
          onChange={(text) => updateBlock({ rightColumnBody: text })}
          placeholder="Text"
          richTextOptions={['heading', 'bold', 'underline', 'italic', 'link', 'document', 'list', 'ordered-list']}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  ${SiteResponsivePadding};

  display: flex;
  flex-wrap: wrap;

  padding-top: 6rem;
  padding-bottom: 6rem;

  .left-column-title,
  .right-column-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.onBackgroundSemiDark};

    line-height: 1.15;

    width: 100%;

    @media (max-width: 1000px) {
      font-size: 1.8rem;
    }
  }

  .left-column-body,
  .right-column-body {
    margin-top: 1rem;
    font-size: 1.15rem;

    @media (max-width: 900px) {
      max-width: 100%;
      font-size: 1.15rem;
    }

    line-height: 1.35;

    color: ${({ theme }) => theme.onBackgroundSemiLight};
  }

  .left-column,
  .right-column {
    flex: 1 1 50%;

    @media (max-width: 900px) {
      flex: 1 1 100%;
    }
  }

  .left-column {
    padding-right: 2rem;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  .right-column {
    padding-left: 2rem;

    @media (max-width: 900px) {
      padding-left: 0;
    }
  }
`
