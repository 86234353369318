'use client'
import { SiteResponsivePadding } from '@app/site/common/components/SiteResponsivePadding'
import styled from '@emotion/styled'

export const Container = styled.div`
  ${SiteResponsivePadding};

  display: flex;
  flex-wrap: wrap;

  img {
    flex: 2 1 20rem;

    overflow: hidden;
    object-fit: cover;
  }

  > div {
    padding-top: 6rem;
    padding-bottom: 6rem;

    @media (max-width: 1000px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    @media (max-width: 800px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    > .headline {
      font-size: 2.5rem;
      font-weight: 600;
      color: ${({ theme }) => theme.onBackgroundSemiDark};

      line-height: 1.15;

      @media (max-width: 1000px) {
        font-size: 1.8rem;
      }
    }

    > .subtitle {
      margin-top: 1rem;
      font-size: 1.15rem;

      @media (max-width: 800px) {
        max-width: 100%;
        font-size: 1.15rem;
      }

      line-height: 1.35;

      color: ${({ theme }) => theme.onBackgroundSemiLight};
    }
  }

  > .content {
    flex: 3 1 30rem;
  }
`
