'use client'
import { SiteResponsivePadding } from '@app/site/common/components/SiteResponsivePadding'
import styled from '@emotion/styled'
import { v4 as uuidv4 } from 'uuid'
import { TipTapField } from '@common/features/blocks/common/TipTapField/TipTapField'
import { useCallback, useEffect, useState } from 'react'
import { IonIcon } from '@ionic/react'
import { trashSharp } from 'ionicons/icons'
import { useMediaPredicate } from 'react-media-hook'
import { EditableImage } from '@common/features/blocks/common/EditableImage'
import { IoMdMail } from 'react-icons/io'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { useUpdateBlock } from '@common/features/block-editor/hooks/use-update-block'
import { SelectImageModal } from '../../block-editor/SelectImageModal'

interface Props {
  id: string

  boardMembers: {
    id: string
    name: string
    title: string

    description?: string | null
    imageUrl?: string | null
    email?: string | null
    phoneNumber?: string | null
  }[]

  editable: boolean
}

export const BoardMembersBlock = ({ id, boardMembers, editable }: Props) => {
  const isMobile = useMediaPredicate('(max-width: 62rem)')
  const { updateBlock } = useUpdateBlock({ blockId: id, active: editable, debounce: false })
  const { updateBlock: updateBlockDebounced } = useUpdateBlock({ blockId: id, active: editable, debounce: true })
  const [members, setMembers] = useState<Props['boardMembers']>(boardMembers)
  const [showImageModalForMemberId, setShowImageModalForMemberId] = useState<string | null>(null)

  useEffect(() => {
    updateBlockDebounced({ boardMembers: members })
  }, [members, updateBlockDebounced])

  const syncState = useCallback(() => {
    void updateBlock({ boardMembers: members })
  }, [members, updateBlock])

  const handleCreateMember = useCallback(() => {
    const id = uuidv4()

    if (!id || !members) {
      return
    }

    setMembers((prev) => [
      ...prev,
      {
        id,
        name: '',
        title: '',
      },
    ])

    // Save the newly created member
    syncState()
  }, [members, syncState])

  const handleDeleteMember = useCallback(
    (id: string) => {
      setMembers((prev) => prev.filter((member) => member.id !== id))
      syncState()
    },
    [syncState],
  )

  const handleUpdateMember = (
    id: string,
    data: {
      name?: string
      title?: string
      imageUrl?: string | null
      email?: string
      phoneNumber?: string
    },
  ) => {
    if (!id || !members) {
      return
    }

    setMembers((prev) =>
      prev.map((member) => {
        if (member.id === id) {
          return {
            ...member,
            ...data,
            imageUrl: data.imageUrl ?? member.imageUrl,
          }
        }

        return member
      }),
    )
  }

  return (
    <Container>
      {members.map((boardMember) => {
        // Hacky way to check if the email is empty, since the field is rich text and will contain JSON fields
        // even though the user has not entered anything
        const isEmailEmpty = (boardMember.email?.length ?? 0) <= 47
        const isPhoneEmpty = (boardMember.phoneNumber?.length ?? 0) <= 47

        return (
          <Entry key={boardMember.id}>
            {!isMobile && (
              <ImagePlaceholder>
                <EditableImage
                  src={boardMember.imageUrl || '/samples/avatar_placeholder.webp'}
                  editable={editable}
                  className="avatar-image"
                  onShowModal={() => setShowImageModalForMemberId(boardMember.id)}
                  onClearImage={() => handleUpdateMember(boardMember.id, { imageUrl: '' })}
                />
              </ImagePlaceholder>
            )}
            <div className="container">
              <TipTapField
                editable={editable}
                className="member-name"
                defaultContent={boardMember.name}
                onChange={(name) => handleUpdateMember(boardMember.id, { name })}
                placeholder="Namn"
                richTextOptions={[]}
              />
              <TipTapField
                editable={editable}
                className="member-role"
                defaultContent={boardMember.title}
                onChange={(title) => handleUpdateMember(boardMember.id, { title })}
                placeholder="Roll"
                richTextOptions={[]}
              />
              {(editable || !isEmailEmpty || isPhoneEmpty) && <div style={{ marginTop: '1rem' }} />}
              {(editable || !isEmailEmpty) && (
                <div className="info-row">
                  <IoMdMail className="row-icon" />
                  <TipTapField
                    editable={editable}
                    className="member-email"
                    defaultContent={boardMember.email ?? ''}
                    onChange={(email) => handleUpdateMember(boardMember.id, { email })}
                    placeholder="E-post (valfritt)"
                    richTextOptions={[]}
                  />
                </div>
              )}
              {(editable || !isPhoneEmpty) && (
                <div className="info-row">
                  <BsFillTelephoneFill className="row-icon" />
                  <TipTapField
                    editable={editable}
                    className="member-phone"
                    defaultContent={boardMember.phoneNumber ?? ''}
                    onChange={(phoneNumber) => handleUpdateMember(boardMember.id, { phoneNumber })}
                    placeholder="Tel.nummer (valfritt)"
                    richTextOptions={[]}
                  />
                </div>
              )}
            </div>
            {editable && (
              <button className="delete" onClick={() => handleDeleteMember(boardMember.id)}>
                <IonIcon icon={trashSharp} />
              </button>
            )}
          </Entry>
        )
      })}
      {editable && (
        <>
          <CreateEntryButton onClick={handleCreateMember}>
            <div className="button-text">+ Lägg till medlem</div>
          </CreateEntryButton>
        </>
      )}
      {editable && (
        <SelectImageModal
          isOpen={!!showImageModalForMemberId}
          onRequestClose={() => setShowImageModalForMemberId(null)}
          onSelect={(url) => {
            if (!showImageModalForMemberId) {
              return
            }

            handleUpdateMember(showImageModalForMemberId, { imageUrl: url })
          }}
        />
      )}
    </Container>
  )
}

const ImagePlaceholder = styled.div`
  background-color: ${({ theme }) => theme.primaryLightest};

  .avatar-image {
    display: flex;
    object-fit: cover;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    max-width: 10rem;
    aspect-ratio: 1;

    height: 100%;
  }
`

const Container = styled.div`
  ${SiteResponsivePadding};

  display: grid;
  grid-template-columns: repeat(2, minmax(30rem, 1fr));
  grid-gap: 1rem;
  grid-auto-rows: minmax(10rem, auto);

  @media (max-width: 62rem) {
    /* Adjust the breakpoint as needed */
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(5rem, auto);
  }

  padding-top: 6rem;
  padding-bottom: 6rem;
`

const CreateEntryButton = styled.button`
  -webkit-appearance: none;
  background-color: transparent;

  display: flex;
  flex-direction: row;

  border: 2px dashed ${({ theme }) => theme.primaryLighter};

  transition: border 0.1s ease-in-out;

  :hover {
    border: 2px dashed ${({ theme }) => theme.primaryLight};
  }

  .button-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    height: 100%;

    color: ${({ theme }) => theme.primaryLight};
  }
`

const Entry = styled.div`
  display: flex;
  flex-direction: row;

  border: 1px solid ${({ theme }) => theme.onBackgroundLightest};

  position: relative;

  .delete {
    position: absolute;
    top: 0;
    right: 0;

    background-color: ${({ theme }) => theme.primaryLight};
    color: ${({ theme }) => theme.background};

    border: none;
    -webkit-appearance: none;
    padding: 0.5rem;

    aspect-ratio: 1;

    > * {
      font-size: 1rem;
    }
  }

  .container {
    padding: 1rem;

    @media (max-width: 62rem) {
      padding: 0.5rem;
    }
  }

  .member-name {
    font-weight: normal;
    font-size: 1.5rem;
    margin: 0.5rem 0 0;

    @media (max-width: 62rem) {
      font-size: 1.15rem;
      margin: 0.25rem 0 0;
    }
  }

  .member-role {
    font-weight: normal;
    font-size: 1.15rem;
    margin-top: 0.35rem;

    color: ${({ theme }) => theme.onBackgroundSemiLight};

    @media (max-width: 62rem) {
      font-size: 1rem;

      margin: 0.25rem 0 0;
    }
  }

  .row-icon {
    margin-top: 0.15rem;
    color: ${({ theme }) => theme.primaryLight};
  }

  .info-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1rem;

    padding: 0 0 0.5rem 0.75rem;
  }

  .member-email {
    font-weight: normal;
    font-size: 1.15rem;

    color: ${({ theme }) => theme.onBackgroundSemiLight};

    @media (max-width: 62rem) {
      font-size: 1rem;

      margin: 0.25rem 0 0;
    }
  }

  .member-phone {
    font-weight: normal;
    font-size: 1.15rem;
    margin-top: 0.35rem;

    color: ${({ theme }) => theme.onBackgroundSemiLight};

    @media (max-width: 62rem) {
      font-size: 1rem;

      margin: 0.25rem 0 0;
    }
  }

  .title {
    font-weight: normal;
    font-size: 1.15rem;
    margin-top: 0.35rem;
  }
`
