import { Link } from '@tiptap/extension-link'

export const CustomLink = Link.extend<{ tooltip: boolean; openOnClick: boolean }>({
  // Add an options object to hold your custom configurations
  addOptions() {
    return {
      ...this.parent?.(), // Preserve any existing options
      tooltip: false, // Default configuration for tooltip
    }
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      'data-tip': {
        default: null,
        parseHTML: (element) => element.getAttribute('data-tip'),
        renderHTML: (attributes) => {
          // Ensure 'href' exists before setting 'data-tip'
          if (!this.options.tooltip || !attributes.href) {
            return {}
          }

          return {
            'data-tip': attributes.href,
          }
        },
      },
    }
  },
})
